import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import { useTranslation } from 'react-i18next'

import GlobalStyles from 'styles/GlobalStyles'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'

import cancel from 'images/png/cancel.png'

const Cancel = () => {
  const ACCOUNT_PATH = '/account'

  const { t, ready } = useTranslation('paymentCancelPage')

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      <StripeCheckoutRedirectContainer>
        <ShapeContainer>
          <LottieShape size={500} animationData={AbstractPaymentAnimation} />
        </ShapeContainer>
        {!ready ? null : (
          <StripeCheckoutContentContainer>
            <StripeCheckoutRedirectHeader>
              <StripeCheckoutImage src={cancel} alt="cancel" />
              {t('cancelMsg')}
            </StripeCheckoutRedirectHeader>
            <StripeCheckoutRedirectDescription>
              {t('noCharge')}
            </StripeCheckoutRedirectDescription>
            <Link
              css={`
                width: 300px;
              `}
              to={ACCOUNT_PATH}
            >
              <StripeCheckoutRedirectButton>
                {t('goToAccount')}
              </StripeCheckoutRedirectButton>
            </Link>
          </StripeCheckoutContentContainer>
        )}
      </StripeCheckoutRedirectContainer>
    </>
  )
}

export default Cancel
